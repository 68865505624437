body {
  color: #291f1f;
  margin: 0;
}

.book-me-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #291f1f;
}

.inquiryform-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #291f1f;
}

.inuiryform-desc {
  font-family: 'Puritan', sans-serif;  
}

@media only screen and (max-width: 505px) {
  .categories-bookme {
    text-align: center;
    margin-bottom: 2px;
    margin-top: 102px;

  }

  .headshotsDiv-bookme {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .portraitsDiv-bookme {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .couplesDiv-bookme {
    width: 48vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }

  .gradDiv-bookme {
    width: 48vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }

  .eventsDiv-bookme {
    width: 48vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }

  .seniorDiv-bookme {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }

  .portfolio-page-image-1 {
    width: 48vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .overlay {
    width: 48vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    background-color: rgba(226, 222, 181, 0.4);
  }

  .book-me-text {
    font-size: 15px;
    padding-top: 50%;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 506px) and (max-width: 750px) {
  .categories-bookme {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }

  .headshotsDiv-bookme {
    width: 49vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .portraitsDiv-bookme {
    width: 49vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .couplesDiv-bookme {
    width: 49vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;

  }

  .gradDiv-bookme {
    width: 49vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }

  .eventsDiv-bookme {
    width: 49vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }

  .seniorDiv-bookme {
    width: 49vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }

  .portfolio-page-image-1 {
    width: 49vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .overlay {
    width: 49vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226, 222, 181, 0.5);
  }

  .headshotsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .portraitsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .couplesDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .gradDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .eventsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .seniorDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .book-me-text {
    font-size: 25px;
    padding-top: 50%;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1024px) {
  .categories-bookme {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }

  .headshotsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .portraitsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .couplesDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;

  }

  .gradDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }

  .eventsDiv-bookme {
    width: 32.8vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }

  .seniorDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }

  .portfolio-page-image-1 {
    width: 32.8vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .overlay {
    width: 32.8vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226, 222, 181, 0.5);
  }

  .headshotsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .portraitsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .couplesDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .gradDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .eventsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .seniorDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .book-me-text {
    font-size: 20px;
    padding-top: 50%;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {

  .categories-bookme {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }

  .headshotsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .portraitsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }

  .couplesDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;

  }

  .gradDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }

  .eventsDiv-bookme {
    width: 32.8vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }

  .seniorDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }

  .portfolio-page-image-1 {
    width: 32.8vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .overlay {
    width: 32.8vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226, 222, 181, 0.5);
  }

  .headshotsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .portraitsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .couplesDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .gradDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .eventsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .seniorDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .book-me-text {
    font-size: 30px;
    padding-top: 55%;
    text-align: center;
    margin-top: 40px;
  }

}

@media only screen and (min-width: 1201px) {
  .categories-bookme {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;
  }

  .headshotsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;
  }

  .portraitsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;
  }

  .couplesDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 3px 3px 3px;

  }

  .gradDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 3px 3px 3px;
  }

  .eventsDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;
  }

  .seniorDiv-bookme {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;

  }

  .portfolio-page-image-1 {
    width: 32.8vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .overlay {
    width: 32.8vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226, 222, 181, 0.5);
    align-items: center;
  }

  .headshotsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .portraitsDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .couplesDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .gradDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .seniorDiv-bookme:hover .overlay {
    opacity: 1;
  }

  .book-me-text {
    font-size: 40px;
    text-align: center;
    margin-top: 40px;
    padding-top: 55%;
  }
}

@media only screen and (max-width: 484px) {
  .bookme-form {
    position: absolute;
    height: 72vw;
    top: -36.5vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    display: none;
  }

  .inquiryform-desc {
    font-size: 1.7vw;
    margin-bottom: 7.5px;
  }


  .bookme-form textarea.form-control {
    max-height: 15vw;
  }

  .bookme-form .form-control {
    font-size: 2vw;
    margin-bottom: 1vw;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .bookme-form .btn-primary {
    font-size: 1.8vw;
    margin-top: 3px;
    padding: 4px;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 485px) and (max-width: 505px) {
  .bookme-form {
    position: absolute;
    height: 72vw;
    top: -36.5vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    display: none;
  }

  .inquiryform-desc {
    font-size: 1.5vw;
    margin-bottom: 7.5px;
  }

  .bookme-form textarea.form-control {
    max-height: 15vw;
  }

  .bookme-form .form-control {
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  .bookme-form .btn-primary {
    font-size: 1.8vw;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 506px) and (max-width: 750px) {
  .bookme-form {
    position: absolute;
    height: 73.5vw;
    top: -37vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
display: none;  }

  .inquiryform-desc {
    font-size: 1.5vw;
    margin-bottom: 10px;
  }

  .bookme-form textarea.form-control {
    max-height: 10vw;
  }

  .bookme-form .form-control {
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  .bookme-form .btn-primary {
    font-size: 1.8vw;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 768px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    display: none;
  }

  .inquiryform-desc {
    font-size: 1vw;
    margin-bottom: 7.5px;
  }

  .bookme-form textarea.form-control {
    max-height: 7vw;
  }

  .bookme-form .form-control {
    font-size: 0.6rem;
    margin-bottom: 1vw;
  }

  .bookme-form .btn-primary {
    font-size: 0.5rem;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 903px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    display: none;
  }

  .inquiryform-desc {
    font-size: 1vw;
  }

  .bookme-form textarea.form-control {
    max-height: 7vw;
  }

  .bookme-form .form-control {
    font-size: 0.6rem;
    margin-bottom: 1vw;
  }

  .bookme-form .btn-primary {
    font-size: 0.7rem;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 904px) and (max-width: 943px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    display: none;
  }

  .inquiryform-desc {
    font-size: 1.4vw;
  }

  .bookme-form textarea.form-control {
    max-height: 7vw;
  }

  .bookme-form .form-control {
    font-size: 0.7rem;
    margin-bottom: 1vw;
  }

  .bookme-form .btn-primary {
    font-size: 0.7rem;
    margin-top: 3px;
  }
}


  @media only screen and (min-width: 941px) and (max-width: 1037px) {
    .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    display: none;
  }

  .inquiryform-desc {
    font-size: 1.4vw;
  }

  .bookme-form textarea.form-control {
    max-height: 7vw;
  }

  .bookme-form .form-control {
    font-size: 0.7rem;
  }

  .bookme-form .btn-primary {
    font-size: 0.7rem;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 1039px) and (max-width: 1100px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-desc {
    font-size: 1.4vw;
  }

  .bookme-form textarea.form-control {
    max-height: 6vw;
  }

  .bookme-form .form-control {
    font-size: 0.7rem;
  }

  .bookme-form .btn-primary {
    font-size: 0.7rem;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1161px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    font-size: 2.5vw;
  }

  .inquiryform-desc {
    font-size: .9vw;
  }

  .bookme-form textarea.form-control {
    max-height: 8vw;
  }

  .bookme-form .btn-primary {
    margin-top: 3px;
  }
}

@media only screen and (min-width: 1162px) and (max-width: 1249px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 2vw;
    padding-right: 2vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    font-size: 2.5vw;
  }

  .inquiryform-desc {
    font-size: .9vw;
  }

  .bookme-form textarea.form-control {
    max-height: 8vw;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1347px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 3vw;
    padding-right: 3vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    font-size: 3vw;
  }

  .inquiryform-desc {
    font-size: .9vw;
  }

  .bookme-form textarea.form-control {
    max-height: 9vw;
  }
}

@media only screen and (min-width: 1348px) {
  .bookme-form {
    position: absolute;
    height: 49.2vw;
    top: -24.6vw;
    background-color: #f4f2de;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 10px;
    padding-left: 3vw;
    padding-right: 3vw;
    font-family: 'Puritan', sans-serif;  
  }

  .inquiryform-header {
    font-size: 3vw;
  }

  .inquiryform-desc {
    font-size: .9vw;
  }
}
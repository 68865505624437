body {
  margin: 0;
  color: #291f1f;
}

.btn-primary {
  --bs-btn-bg: #737154;
  --bs-btn-border-color: #737154;
  --bs-btn-hover-bg: #E2DEB5;
  --bs-btn-hover-border-color: #737154;
}

.btn-custom-portfolio {
  color: #FFFFFF;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 18px;
  font-size: 36px;
  border-radius: 20px;
}

:not(.btn-check)+.btn:hover {
  color: #737154;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #E2DEB5;
}

.book-portfolio {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 316px) {
  .portfolio-content {
    text-align: center;
    margin-top: 102px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 2px;
  }

  .portfolio-image {
    width: 32vw;
    margin: 2px 2px 2px;
  }

  .portfolio-image-wide {
    height: 32vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 30.6vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 22.5vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 12px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }


  .hide {
    display: none;
  }
}

@media only screen and (min-width: 317px) and (max-width: 420px) {
  .portfolio-content {
    text-align: center;
    margin-top: 102px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 2px;
  }

  .portfolio-image {
    width: 32vw;
    margin: 2px 2px 2px;
  }

  .portfolio-image-wide {
    height: 32vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 31.5vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 23.1vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 12px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }


  .hide {
    display: none;
  }

}

@media only screen and (min-width: 421px) and (max-width: 480px) {
  .portfolio-content {
    text-align: center;
    margin-top: 102px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 2px;
  }

  .portfolio-image {
    width: 32vw;
    margin: 2px 2px 2px;
  }

  .portfolio-image-wide {
    height: 32vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 31.6vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 23.5vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }

  .hide {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 808px) {
  .portfolio-content {
    text-align: center;
    margin-top: 103px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 3px;
  }

  .portfolio-image {
    width: 32vw;
    margin: 3px 3px 3px;
  }

  .portfolio-image-wide {
    height: 32vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 32vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 23.5vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
  }
}

@media only screen and (min-width: 809px) and (max-width: 1024px) {
  .portfolio-content {
    text-align: center;
    margin-top: 103px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 3px;
  }

  .portfolio-image {
    width: 32.5vw;
    margin: 3px 3px 3px;
  }

  .portfolio-image-wide {
    height: 32.5vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 32.5vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 24.2vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .portfolio-content {
    text-align: center;
    margin-top: 103px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 3px;
  }

  .portfolio-image {
    width: 32.7vw;
    margin: 3px 3px 3px;
  }

  .portfolio-image-wide {
    height: 32.7vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 32.7vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 24.4vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 20px;
    border-style: solid;
    padding: 10px;
  }
}

@media only screen and (min-width: 1201px) {
  .portfolio-content {
    text-align: center;
    margin-top: 100px;
    font-size: 0;
  }

  .portfolio-images {
    display: inline-block;
    margin-bottom: 2px;
    width: 100vw;
    margin-top: 3px;
  }

  .portfolio-image {
    width: 32.8vw;
    margin: 3px 3px 3px;
  }

  .portfolio-image-wide {
    height: 32.8vw;
    margin: 3px 3px 3px;
  }

  .oasis-image-wide {
    width: 32.8vw;
    margin: 3px 3px 3px;
  }

  .oasis-image {
    width: 24.5vw;
    margin: 3px 3px 3px;
  }

  .book-portfolio {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-portfolio-size {
    font-size: 20px;
    border-style: solid;
    padding: 10px;
  }

}
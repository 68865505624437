body {
  color: #291f1f;
  margin: 0;
}

.home-links {
  background-color: #291f1f;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  text-align: center;
  margin-top: 6px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 525px) {
  .home-links {
    font-size: 10px;
  }

  .iglogo {
    width: 21.5px;
  }

  .ttlogo {
    width: 15px;
  }
}

@media only screen and (min-width: 526px) and (max-width: 690px) {
  .home-links {
    font-size: 12px;
  }

  .iglogo {
    width: 35px;
  }

  .ttlogo {
    width: 25px;
  }
}

@media only screen and (min-width: 691px) and (max-width: 925px) {
  .home-links {
    font-size: 12px;
  }

  .iglogo {
    width: 35px;
  }

  .ttlogo {
    width: 25px;
  }
}

@media only screen and (min-width: 926px) and (max-width: 1024px) {
  .home-links {
    font-size: 12px;
  }

  .iglogo {
    width: 35px;
  }

  .ttlogo {
    width: 25px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .home-links {
    font-size: 12px;
  }

  .iglogo {
    width: 35px;
  }

  .ttlogo {
    width: 25px;
  }
}

@media only screen and (min-width: 1201px) {
  .home-links {
    font-size: 12px;
  }

  .iglogo {
    width: 35px;
  }

  .ttlogo {
    width: 25px;
  }
}
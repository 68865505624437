body {
  color: #291f1f;
  margin: 0;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.card {
  display: inline-block;
  border-radius: 0px;
  --bs-card-border-color: none;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.about-me-dina {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.about-me-text {
  font-family: 'Puritan', sans-serif;  
}

.about-me {
  background-color: #f4f2de;
}

@media only screen and (max-width: 525px) {
  .home-images-center {
    text-align: center;
    margin-top: 100px;
  }

  .home-images {
    margin-left: 0px;
    margin-right: 6px;
  }

  .home-page-image {
    width: 65vw;
    margin: 6px 0px 6px 6px;
  }

  .about-me {
    height: 715px;
  }

  .about-me-elements {
    text-align: center;
    justify-content: center;
  }

  .about-me-text {
    width: 300px;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .about-me-dina {
    font-size: 36px;
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 0px;
  }

  .home-page-image-me {
    width: 250px;
    height: 375px;
  }
}

@media only screen and (min-width: 526px) and (max-width: 690px) {
  .home-images-center {
    text-align: center;
    margin-top: 100px;
  }

  .home-images {
    margin-left: 0px;
  }

  .home-page-image {
    width: 30vw;
    margin: 12px 0px 12px 12px;
  }

  .about-me {
    height: 350px;
    align-items: center;
    display: flex;

  }

  .about-me-elements {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

  }

  .about-me-text {
    width: 35vw;
    font-size: 10px;
    text-align: right;
    align-items: center;
    justify-content: center;
  }

  .about-me-dina {
    font-size: 24px;
  }

  .home-page-image-me {
    height: 350px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 691px) and (max-width: 925px) {
  .home-images-center {
    text-align: center;
    margin-top: 100px;
  }

  .home-images {
    margin-left: 0px;
  }

  .home-page-image {
    width: 30vw;
    margin: 12px 0px 12px 12px;
  }

  .about-me {
    height: 550px;
    align-items: center;
    display: flex;

  }

  .about-me-elements {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

  }

  .about-me-text {
    width: 35vw;
    font-size: 14px;
    text-align: right;
    align-items: center;
    justify-content: center;
  }

  .about-me-dina {
    font-size: 34px;
  }

  .home-page-image-me {
    height: 550px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 926px) and (max-width: 1024px) {
  .home-images-center {
    text-align: center;
    margin-top: 100px;

  }

  .home-images {
    margin-left: 0px;
  }

  .home-page-image {
    width: 30vw;
    margin: 12px 0px 12px 12px;
  }

  .about-me {
    height: 650px;
    align-items: center;
    display: flex;

  }

  .about-me-elements {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

  }

  .about-me-text {
    width: 35vw;
    font-size: 18px;
    text-align: right;
    align-items: center;
    justify-content: center;
  }

  .about-me-dina {
    font-size: 40px;
  }

  .home-page-image-me {
    height: 650px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .home-images-center {
    text-align: center;
    margin-top: 100px;

  }

  .home-images {
    margin-left: 0px;
  }

  .home-page-image {
    width: 30vw;
    margin: 12px 0px 12px 12px;
  }

  .about-me {
    height: 650px;
    align-items: center;
    display: flex;
  }

  .about-me-elements {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

  }

  .about-me-text {
    width: 35vw;
    font-size: 20px;
    text-align: right;
    align-items: center;
    justify-content: center;
  }

  .about-me-dina {
    font-size: 40px;
  }

  .home-page-image-me {
    height: 650px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1201px) {
  .home-images-center {
    text-align: center;
    margin-top: 100px;

  }

  .home-images {
    margin-left: 0px;
  }

  .home-page-image {
    width: 30vw;
    margin: 12px 0px 12px 12px;
  }

  .about-me {
    height: 650px;
    align-items: center;
    display: flex;

  }

  .about-me-elements {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

  }

  .about-me-text {
    width: 35vw;
    font-size: 22px;
    text-align: right;
    align-items: center;
    justify-content: center;
  }

  .about-me-dina {
    font-size: 48px;
  }

  .home-page-image-me {
    height: 650px;
    margin-left: 30px;
  }
}
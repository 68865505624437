body {
    color: #291f1f;
    margin: 0;
}

.collapsing {
    display: none;
}

.custom-navbar {
    background-color: #ded9a7;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    height: 100px;
}

.custom-navbar .custom-color {
    color: #291f1f;
}

.custom-collapse {
    flex-grow: 0;
}

.dropdown-toggle::after {
    display: none !important;
}

.navbar-nav {
    --bs-nav-link-color: #291f1f;
}

.show {
    background-color: #ded9a7;
    border-radius: 0px;
    box-shadow: none;
    text-align: center;
}

.dropdown-item:hover {
    background-color: #ded9a7;
    color: #6b6846;
}

.dropdown-menu {
    margin-right: 50px;
}

.navbar-toggler {
    border: none;
    border-radius: 0px;
    color: transparent;
}

.chicago {
    font-size: 16px;
    font-weight: 200;
}

@media only screen and (max-width: 480px) {
    .custom-navbar {
        background-color: #ded9a7;
        font-size: 50px;
        display: flex;
        --bs-navbar-padding-y: 30px;
        --bs-navbar-brand-padding-y: 0rem;
    }

    .logo {
        width: 250px;      
        margin-left: 20px;
    }

    .navbar-toggler-icon {
        margin-right: 10px;
    }

    .container {
        max-width: 100%;
        --bs-gutter-x: 0px;
    }

    .navbar-nav {
        height: 100vh;
        justify-content: center;
        flex-wrap: inherit;
        padding-bottom: 100px;
    }

    .hide {
        display: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .custom-navbar {
        background-color: #ded9a7;
        font-size: 50px;
        display: flex;
        height: 100px;
        --bs-navbar-padding-y: 30px;
        --bs-navbar-brand-padding-y: 0rem;

    }

    .logo {
        width: 300px;      
        margin-left: 20px;
    }

    .navbar-toggler-icon {
        margin-right: 10px;
    }

    .container {
        max-width: 100%;
        --bs-gutter-x: 0px;
    }

    .navbar-nav {
        height: 100vh;
        justify-content: center;
        flex-wrap: inherit;
        padding-bottom: 100px;
    }

    .hide {
        display: none;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .logo {
        width: 300px;      
    }

    .container {
        max-width: 98%;
    }

    .links {
        justify-content: flex-end!important;
    }

    .hide2 {
        display: none;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .logo {
        width: 350px;      
    }

    .container {
        max-width: 98%;
    }

    .links {
        justify-content: flex-end!important;
    }

    .hide2 {
        display: none;
    }
}

@media only screen and (min-width: 1201px) {
    .logo {
        width: 350px;
    }

    .container {
        max-width: 98%;
    }

    .links {
        justify-content: flex-end!important;
    }

    .hide2 {
        display: none;
    }
}
body {
  color: #291f1f;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;  
}

.review1-text {
  font-family: 'Puritan', sans-serif;  
}

.review2-text {
  font-family: 'Puritan', sans-serif;  
}

.review3-text {
  font-family: 'Puritan', sans-serif;  
}

.review4-text {
  font-family: 'Puritan', sans-serif;  
}

.review5-text {
  font-family: 'Puritan', sans-serif;  
}

.leave-review {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 525px) {
  p {
    margin-bottom: 0px;
  }
  
  .review1 {
    width: 100vw;
    position: relative;
  }

  .review1-elements {
    text-align: center;
    padding-top: 100px;
    justify-content: center;
  }

  .review1-text {
    width: 80%;
    font-size: 2.5vw;
    text-align: center;
    display: inline-block;
    padding: 4vw;
    position: absolute;
    top: 35vw;
    left: 10%;
    background-color: #f4f2de;
  }

  .review1-image {
    width: 100%;
    margin-bottom: 4px;
  }

  .hide1-reviews {
    display: none;
  }

  .review2 {
    width: 100vw;
    position: relative;
  }

  .review2-elements {
    text-align: center;
    justify-content: center;
  }

  .review2-text {
    width: 80%;
    font-size: 2.5vw;
    text-align: center;
    display: inline-block;
    padding: 4vw;
    padding-bottom: 20px;
    position: absolute;
    left: 10%;
    top: 20vw;
    background-color: #f4f2de;
  }

  .review2-image {
    width: 100%;
    margin-bottom: 4px;
  }

  .review3 {
    width: 100vw;
    margin-bottom: 4px;
    position: relative;
  }

  .review3-elements {
    text-align: center;
    justify-content: center;
  }

  .review3-text {
    width: 80%;
    font-size: 2.5vw;
    text-align: center;
    display: inline-block;
    padding: 4vw;
    position: absolute;
    left: 10%;
    top: 4vw;
    background-color: #f4f2de;
  }

  .review3-image {
    width: 100%;

  }

  .review4 {
    width: 100vw;
    margin-bottom: 4px;
    position: relative;
  }

  .review4-elements {
    text-align: center;
    justify-content: center;
  }

  .review4-text {
    width: 80%;
    font-size: 2.5vw;
    text-align: center;
    display: inline-block;
    padding: 4vw;
    position: absolute;
    background-color: #f4f2de;
    top: 12vw;
    left: 10%;
  }

  .review4-image {
    width: 100%;
  }

  .review5 {
    width: 100vw;
    background-color: #FFFFFF;
    position: relative;
  }

  .review5-elements {
    text-align: center;
    justify-content: center;
  }

  .review5-text {
    width: 80%;
    font-size: 2.5vw;
    text-align: center;
    display: inline-block;
    padding: 4vw;
    position: absolute;
    background-color: #f4f2de;
    bottom: 12vw;
    left: 10%;
  }

  .review5-image {
    width: 100%;
  }

  .leave-review {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .leave-review-size {
    font-size: 12px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }
}

@media only screen and (min-width: 526px) and (max-width: 650px) {
  .hide2-reviews {
    display: none;
  }

  .review1 {
    height: 450px;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: 100px;
  }

  .review1-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review1-text {
    width: 35%;
    font-size: 12px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review1-image {
    margin-left: 20px;
  }

  .review2 {
    height: 450px;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review2-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review2-text {
    width: 35%;
    font-size: 18px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review2-image {
    margin-right: 20px;
  }

  .review3 {
    margin-top: -6px;
    height: 450px;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
  }

  .review3-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review3-text {
    width: 35%;
    font-size: 18px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review3-image {
    margin-left: 20px;
  }

  .review4 {
    height: 450px;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review4-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review4-text {
    width: 35%;
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review4-image {
    margin-right: 20px;
  }

  .review5 {
    margin-top: -6px;
    height: 450px;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
  }

  .review5-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review5-text {
    width: 35%;
    font-size: 12px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review5-image {
    margin-left: 20px;
  }

  .leave-review {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .leave-review-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }
  
}


@media only screen and (min-width: 651px) and (max-width: 808px) {
  .hide2-reviews {
    display: none;
  }

  .review1 {
    height: 550px;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: 100px;
  }

  .review1-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review1-text {
    width: 35%;
    font-size: 14px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review1-image {
    margin-left: 20px;
  }

  .review2 {
    height: 550px;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review2-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review2-text {
    width: 35%;
    font-size: 24px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review2-image {
    margin-right: 20px;
  }

  .review3 {
    margin-top: -6px;
    height: 550px;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
  }

  .review3-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review3-text {
    width: 35%;
    font-size: 18px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review3-image {
    margin-left: 20px;
  }

  .review4 {
    height: 550px;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review4-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review4-text {
    width: 35%;
    font-size: 20px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review4-image {
    margin-right: 20px;
  }

  .review5 {
    height: 550px;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review5-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review5-text {
    width: 35%;
    font-size: 14px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review5-image {
    margin-left: 20px;
  }

  .leave-review {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .leave-review-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }
}

@media only screen and (min-width: 809px) and (max-width: 1024px) {
  .hide2-reviews {
    display: none;
  }

  .review1 {
    height: 80vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: 100px;
  }

  .review1-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review1-text {
    width: 30%;
    font-size: 16px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review1-image {
    margin-left: 30px;
  }

  .review2 {
    height: 80vh;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review2-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review2-text {
    width: 30%;
    font-size: 30px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review2-image {
    margin-right: 30px;
  }

  .review3 {
    height: 80vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review3-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review3-text {
    width: 30%;
    font-size: 24px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review3-image {
    margin-left: 30px;
  }

  .review4 {
    height: 80vh;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review4-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review4-text {
    width: 30%;
    font-size: 24px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review4-image {
    margin-right: 30px;
  }

  .review5 {
    height: 80vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review5-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review5-text {
    width: 30%;
    font-size: 16px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review5-image {
    margin-left: 30px;
  }

  .leave-review {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .leave-review-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .hide2-reviews {
    display: none;
  }

  .review1 {
    height: 85vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: 100px;
  }

  .review1-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review1-text {
    width: 40%;
    font-size: 20px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review1-image {
    margin-left: 60px;
  }

  .review2 {
    height: 85vh;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review2-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review2-text {
    width: 40%;
    font-size: 42px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review2-image {
    margin-right: 60px;
  }

  .review3 {
    height: 85vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review3-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review3-text {
    width: 40%;
    font-size: 28px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review3-image {
    margin-left: 60px;
  }

  .review4 {
    height: 85vh;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review4-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review4-text {
    width: 40%;
    font-size: 32px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review4-image {
    margin-right: 60px;
  }

  .review5 {
    height: 85vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review5-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review5-text {
    width: 40%;
    font-size: 22px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review5-image {
    margin-left: 60px;
  }

  .leave-review {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .leave-review-size {
    font-size: 20px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }
}

@media only screen and (min-width: 1201px) {
  .hide2-reviews {
    display: none;
  }

  .review1 {
    height: 85vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: 100px;
  }

  .review1-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review1-text {
    width: 40%;
    font-size: 24px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review1-image {
    margin-left: 60px;
  }

  .review2 {
    height: 85vh;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review2-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review2-text {
    width: 40%;
    font-size: 48px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review2-image {
    margin-right: 60px;
  }

  .review3 {
    height: 85vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review3-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review3-text {
    width: 40%;
    font-size: 36px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review3-image {
    margin-left: 60px;
  }

  .review4 {
    height: 85vh;
    background-color: #f4f2de;
    align-items: center;
    display: inline-block;
  }

  .review4-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review4-text {
    width: 40%;
    font-size: 36px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .review4-image {
    margin-right: 60px;
  }

  .review5 {
    height: 85vh;
    background-color: #ffffff;
    align-items: center;
    display: inline-block;
    margin-top: -6px;
  }

  .review5-elements {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .review5-text {
    width: 40%;
    font-size: 24px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  .review5-image {
    margin-left: 60px;
  }

  .leave-review {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .leave-review-size {
    font-size: 20px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }
}
body {
  margin: 0;
}

.solosession-pricinginfo {
  font-family: 'Puritan', sans-serif;
}

.smallgroupsession-pricinginfo {
  font-family: 'Puritan', sans-serif;
}

.mediumgroupsession-pricinginfo {
  font-family: 'Puritan', sans-serif;
}

.sessionheader {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.mediumsessionheader {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.interest-form {
  padding-top: 35vh;
  height: 78vh;
}

@media only screen and (max-width: 618px) {
  .interest-form {
    padding-top: 45vh;
    text-align: center;
    height: 94vh;
  }

  .hide-bookgrad {
    display: none;
  }

  hr {
    margin: 0.5rem 0;
  }

  .sessionheader {
    font-size: 12vw;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .mediumsessionheader {
    font-size: 12vw;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .solosession {
    width: 100vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-block;
    padding-top: 100px;
  }

  .solosession-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding-top: 8px;
    margin-top: -23vw;
  }

  .solosession-image {
    width: 100%;
  }

  .solosession-pricinginfo {
    background-color: #f4f2de;
    padding-top: 10px;
    display: block;
    width: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }

  .solosession-price {
    text-align: center;
    font-size: 23px;
    margin-bottom: 0px;
  }

  .solosession-refund {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;
    ;
  }

  .solosession-info {
    font-size: 19px;
    word-wrap: break-word;
  }

  .smallgroupsession {
    width: 100vw;
    align-items: center;
    display: inline-block;
  }

  .smallgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .smallgroupsession-pricinginfo {
    background-color: #FFFFFF;
    padding-top: 10px;
    display: block;
    width: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }

  .smallgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

  .mediumgroupsession {
    width: 100vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-block;
  }

  .mediumgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .mediumgroupsession-pricinginfo {
    background-color: #f4f2de;
    padding-top: 10px;
    display: block;
    width: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }

  .mediumgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

  .custom-calendly {
    height: 700px;
    margin-bottom: 40px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}


@media only screen and (min-width: 619px) and (max-width: 680px) {
  .sessions {
    padding-top: 100px;
    margin-top: .25vw;
    text-align: center;
  }

  .hide-bookgrad {
    display: none;
  }

  hr {
    margin: 0.5rem 0;
  }

  .sessionheader {
    font-size: 4vw;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .solosession {
    margin-top: -100px;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-grid;
    margin: .25vw;
  }

  .solosession-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding-top: 8px;
    margin-top: -8vw;
  }

  .solosession-image {
    width: 100%;
  }

  .solosession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .solosession-price {
    text-align: center;
    font-size: 23px;
    margin-bottom: 0px;
  }

  .solosession-refund {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;

  }

  .solosession-info {
    font-size: 18px;
    word-wrap: break-word;
    padding-right: 35px;
  }

  .duosession-info {
    font-size: 18px;
    word-wrap: break-word;
  }

  .smallgroupsession {
    margin: .25vw;
    width: 32.5vw;
    align-items: center;
    display: inline-grid;
  }

  .smallgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .smallgroupsession-pricinginfo {
    background-color: #FFFFFF;
    padding-top: 10px;
    display: block;
    height: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .smallgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

  .creativegroupsession-info {
    font-size: 10.75px;
    word-wrap: break-word;
  }

  .mediumgroupsession {
    margin: .25vw;
    display: inline-grid;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
  }

  .mediumgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .mediumgroupsession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .mediumgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;

  }

  .custom-calendly {
    height: 700px;
    margin-bottom: 40px;
  }

  .home-links-bookgrad {
    background-color: #291f1f;
    font-size: 12px;
    padding: 20px;
    color: #f4f4f4;
    text-align: center;
    margin-top: 12px;
  }
}


@media only screen and (min-width: 681px) and (max-width: 858px) {
  .sessions {
    padding-top: 100px;
    margin-top: .25vw;
    text-align: center;
  }

  .hide-bookgrad {
    display: none;
  }

  hr {
    margin: 0.5rem 0;
  }

  .sessionheader {
    font-size: 4vw;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .solosession {
    margin-top: -100px;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-grid;
    margin: .25vw;
  }

  .solosession-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding-top: 8px;
    margin-top: -8vw;
  }

  .solosession-image {
    width: 100%;
  }

  .solosession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .solosession-price {
    text-align: center;
    font-size: 23px;
    margin-bottom: 0px;
  }

  .solosession-refund {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;

  }

  .solosession-info {
    font-size: 18px;
    word-wrap: break-word;
    padding-right: 35px;
  }

  .duosession-info {
    font-size: 18px;
    word-wrap: break-word;
  }

  .smallgroupsession {
    margin: .25vw;
    width: 32.5vw;
    align-items: center;
    display: inline-grid;
  }

  .smallgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .smallgroupsession-pricinginfo {
    background-color: #FFFFFF;
    padding-top: 10px;
    display: block;
    height: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .smallgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

  .mediumgroupsession {
    margin: .25vw;
    display: inline-grid;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
  }

  .mediumgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .mediumgroupsession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .mediumgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;

  }

  .custom-calendly {
    height: 700px;
    margin-bottom: 40px;
  }

  .home-links-bookgrad {
    background-color: #291f1f;
    font-size: 12px;
    padding: 20px;
    color: #f4f4f4;
    text-align: center;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 859px) and (max-width: 1024px) {
  .sessions {
    padding-top: 100px;
    margin-top: .25vw;
    text-align: center;
  }

  .hide-bookgrad {
    display: none;
  }

  hr {
    margin: 0.5rem 0;
  }

  .sessionheader {
    font-size: 4vw;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .solosession {
    margin-top: -100px;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-grid;
    margin: .25vw;
  }

  .solosession-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding-top: 8px;
    margin-top: -8vw;
  }

  .solosession-image {
    width: 100%;
  }

  .solosession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .solosession-price {
    text-align: center;
    font-size: 23px;
    margin-bottom: 0px;
  }

  .solosession-refund {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;

  }

  .solosession-info {
    font-size: 22px;
    word-wrap: break-word;
  }

  .duosession-info {
    font-size: 22px;
    word-wrap: break-word;
  }

  .smallgroupsession {
    margin: .25vw;
    width: 32.5vw;
    align-items: center;
    display: inline-grid;
  }

  .smallgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .smallgroupsession-pricinginfo {
    background-color: #FFFFFF;
    padding-top: 10px;
    display: block;
    height: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .smallgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

  .mediumgroupsession {
    margin: .25vw;
    display: inline-grid;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
  }

  .mediumgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .mediumgroupsession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .mediumgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;

  }

  .custom-calendly {
    height: 700px;
    margin-bottom: 40px;
  }

  .home-links-bookgrad {
    background-color: #291f1f;
    font-size: 12px;
    padding: 20px;
    color: #f4f4f4;
    text-align: center;
    margin-top: 12px;
  }

  .creativegroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }
}

@media only screen and (min-width: 1025px) {
  .sessions {
    padding-top: 100px;
    margin-top: .25vw;
    text-align: center;
  }

  .hide-bookgrad {
    display: none;
  }

  hr {
    margin: 0.5rem 0;
  }

  .sessionheader {
    font-size: 4vw;
    text-align: center;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .solosession {
    margin-top: -100px;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-grid;
    margin: .25vw;
  }

  .solosession-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
    display: inline-block;
    padding-top: 8px;
    margin-top: -8vw;
  }

  .solosession-image {
    width: 100%;
  }

  .solosession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .solosession-price {
    text-align: center;
    font-size: 23px;
    margin-bottom: 0px;
  }

  .solosession-refund {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;

  }

  .solosession-info {
    font-size: 22px;
    word-wrap: break-word;
    padding-right: 105px;
  }

  .duosession-info {
    font-size: 22px;
    word-wrap: break-word;
  }

  .smallgroupsession {
    margin: .25vw;
    width: 32.5vw;
    align-items: center;
    display: inline-grid;
  }

  .smallgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .smallgroupsession-pricinginfo {
    background-color: #FFFFFF;
    padding-top: 10px;
    display: block;
    height: auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .smallgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

  .mediumgroupsession {
    margin: .25vw;
    display: inline-grid;
    width: 32.5vw;
    background-color: #FFFFFF;
    align-items: center;
  }

  .mediumgroupsession-elements {
    text-align: center;
    justify-content: center;
  }


  .mediumgroupsession-pricinginfo {
    background-color: #ffffff;
    padding-top: 10px;
    display: block;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    background-color: #f4f2de;
  }

  .mediumgroupsession-info {
    font-size: 14px;
    word-wrap: break-word;

  }

  .custom-calendly {
    height: 700px;
    margin-bottom: 40px;
  }

  .creativegroupsession-info {
    font-size: 14px;
    word-wrap: break-word;
  }

}
body {
  margin: 0;
  color: #291f1f;
}

.form-control {
  border-radius: 0px;
  margin-bottom: 10px;
}

.btn-primary {
  --bs-btn-bg: #737154;
  --bs-btn-border-color: #737154;
  --bs-btn-hover-bg: #E2DEB5;
  --bs-btn-hover-border-color: #737154;
}

.btn-custom {
  color: #FFFFFF;
  text-align: right;  
  margin-top: 10px;
}

:not(.btn-check)+.btn:hover {
  color: #737154;
}

.portrait-pricinginfo {
  font-family: 'Puritan', sans-serif;  
  background-color: #f4f2de;
}

@media only screen and (max-width: 525px) {
  .portraitsessionheader {
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
  }

  .portraitsession {
    height: auto;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
  }

  .portrait-elements {
    margin: 0 auto;
    justify-content: center;
    margin-top: 103px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .portrait-text {
    width: 100%;
  }

  .bookportrait-image {
    width: 31vw;
    margin: 3px;
  }

  .portrait-pricinginfo {
    height: 425px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .portrait-price {
    text-align: left;
    font-size: 36px;
    margin-bottom: 0px;
  }

  .portrait-refund {
    margin-top: -5px;
    width: 100%;
    font-size: 14px;
    text-align: left;
  }

  .portrait-info {
    font-size: 33px;
    word-wrap: break-word;
  }
}

@media only screen and (min-width: 526px) and (max-width: 676px) {
  .portraitsessionheader {
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
  }

  .portraitsession {
    height: auto;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
  }

  .portrait-elements {
    margin: 0 auto;
    justify-content: center;
    margin-top: 103px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .portrait-text {
    width: 100%;
  }

  .bookportrait-image {
    width: 32vw;
    margin: 3px;
  }

  .portrait-pricinginfo {
    height: 425px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .portrait-price {
    text-align: left;
    font-size: 36px;
    margin-bottom: 0px;
  }

  .portrait-refund {
    margin-top: -5px;
    width: 100%;
    font-size: 14px;
    text-align: left;
  }

  .portrait-info {
    font-size: 33px;
    word-wrap: break-word;
  }
}


@media only screen and (min-width: 651px) and (max-width: 1199px) {
  .portraitsessionheader {
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
  }

  .portraitsession {
    height: auto;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
  }

  .portrait-elements {
    margin: 0 auto;
    justify-content: center;
    margin-top: 103px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .portrait-text {
    width: 100%;
  }

  .bookportrait-image {
    width: 32vw;
    margin: 3px;
  }

  .portrait-pricinginfo {
    height: 425px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    margin-top: 3px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .portrait-price {
    text-align: left;
    font-size: 36px;
    margin-bottom: 0px;
  }

  .portrait-refund {
    margin-top: -5px;
    width: 100%;
    font-size: 14px;
    text-align: left;
  }

  .portrait-info {
    font-size: 33px;
    word-wrap: break-word;
  }
}

@media only screen and (min-width: 1200px) {
  .seniorshide1 {
    display: none;
  }
  
  .portraitsessionheader {
    font-size: 44px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }

  .portraitsession {
    width: 100vw;
    background-color: #FFFFFF;
    align-items: center;
    display: inline-block;
    margin: 0px auto;
  }

  .portrait-elements {
    justify-content: center;
    margin-top: 106px;
    display: flex;
  }

  .portrait-text {
    width: 33vw;
  }

  .bookportrait-image {
    width: 32.5vw;
    margin-right: 6px;
  }

  .portrait-pricinginfo {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    display: block;
  }

  .portrait-price {
    text-align: left;
    font-size: 48px;
    margin-bottom: 0px;
  }

  .portrait-refund {
    margin-top: -5px;
    width: 80%;
    font-size: 18px;
    text-align: left;
  }

  .portrait-info {
    font-size: 33px;
    word-wrap: break-word;
  }
}
body {
  margin: 0;
  color: #291f1f;
}

.btn-primary {
  --bs-btn-bg: #737154;
  --bs-btn-border-color: #737154;
  --bs-btn-hover-bg: #E2DEB5;
  --bs-btn-hover-border-color: #737154;
}

.btn-custom-portraits {
  color: #FFFFFF;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 18px;
  font-size: 36px;
  border-radius: 20px;
}

:not(.btn-check)+.btn:hover {
  color: #737154;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #E2DEB5;
}

.book-events {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

@media only screen and (max-width: 420px) {
  .events-content {
    text-align: center;
    margin-top: 102px;
    font-size: 0;
  }

  .events-images {
    display: inline-block;
    margin-bottom: 2px;
  }

  .events-image {
    width: 48vw;
    margin: 3px 3px 3px;
  }

  .book-events {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-events-size {
    font-size: 12px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }


  .hide {
    display: none;
  }

}

@media only screen and (min-width: 421px) and (max-width: 480px) {
  .events-content {
    text-align: center;
    margin-top: 102px;
    font-size: 0;
  }

  .events-images {
    display: inline-block;
    margin-bottom: 2px;
  }

  .events-image {
    width: 48vw;
    margin: 3px 3px 3px;
  }

  .book-events {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-events-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
    border-width: 1px;
  }

  .hide {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 808px) {
  .events-content {
    text-align: center;
    margin-top: 103px;
    font-size: 0;
  }

  .events-images {
    display: inline-block;
    margin-bottom: 3px;
  }

  .events-image {
    width: 48.8vw;
    margin: 3px 3px 3px;
  }
  .book-events {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-events-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
  }
}

@media only screen and (min-width: 809px) and (max-width: 1024px) {
  .events-content {
    text-align: center;
    margin-top: 103px;
    font-size: 0;
  }

  .events-images {
    display: inline-block;
    margin-bottom: 3px;
  }

  .events-image {
    width: 49.2vw;
    margin: 3px 3px 3px;
  }

  .book-events {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-events-size {
    font-size: 16px;
    border-style: solid;
    padding: 10px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .events-content {
    text-align: center;
    margin-top: 103px;
    font-size: 0;
  }

  .events-images {
    display: inline-block;
    margin-bottom: 3px;
  }

  .events-image {
    width: 49.2vw;
    margin: 3px 3px 3px;
  }

  .book-events {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-events-size {
    font-size: 20px;
    border-style: solid;
    padding: 10px;
  }
}

@media only screen and (min-width: 1201px) {
  .events-content {
    text-align: center;
    margin-top: 100px;
    font-size: 0;
  }

  .events-images {
    display: inline-block;
    margin-bottom: 2px;
    width: 100vw;
    margin-top: 3px;
  }

  .events-image {
    width: 49.5vw;
    margin: 3px 3px 3px;
  }

  .book-events {
    background-color: #291f1f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .book-events-size {
    font-size: 20px;
    border-style: solid;
    padding: 10px;

  }
}
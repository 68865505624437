body {
  color: #291f1f;
  margin: 0;
}

.text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #291f1f;
}

@media only screen and (max-width: 505px) {
  .categories {
    text-align: center;
    margin-bottom: 2px;
    margin-top: 102px;

  }
  
  .headshotsDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .portraitsDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .couplesDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }
  
  .gradDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }
  
  .eventsDiv {
    width: 48vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }
  
  .seniorDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }

  .familyDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }
  
  .portfolio-page-image-1 {
    width: 48vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .overlay {
    width: 48vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    background-color: rgba(226,222,181,0.4);
  }
  
  .text {
    font-size: 24px;
    padding-top: 45%;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 506px) and (max-width: 750px) {
  .categories {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }
  
  .headshotsDiv {
    width: 49vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .portraitsDiv {
    width: 49vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .couplesDiv {
    width: 49vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;

  }
  
  .gradDiv {
    width: 49vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }
  
  .eventsDiv {
    width: 49vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }
  
  .seniorDiv {
    width: 49vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }

  .familyDiv {
    width: 48vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }
  
  .portfolio-page-image-1 {
    width: 49vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .overlay {
    width: 49vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226,222,181,0.5);
  }
  
  .headshotsDiv:hover .overlay {
    opacity: 1;
  }
  
  .portraitsDiv:hover .overlay {
    opacity: 1;
  }
  
  .couplesDiv:hover .overlay {
    opacity: 1;
  }
  
  .gradDiv:hover .overlay {
    opacity: 1;
  }
  
  .eventsDiv:hover .overlay {
    opacity: 1;
  }
  
  .seniorDiv:hover .overlay {
    opacity: 1;
  }

  .familyDiv:hover .overlay {
    opacity: 1;
  }
  
  .text {
    font-size: 30px;
    padding-top: 45%;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1024px) {
  .categories {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }
  
  .headshotsDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .portraitsDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .couplesDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;

  }
  
  .gradDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }
  
  .eventsDiv {
    width: 32.8vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }
  
  .seniorDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }
  
  .portfolio-page-image-1 {
    width: 32.8vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .overlay {
    width: 32.8vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226,222,181,0.5);
  }
  
  .headshotsDiv:hover .overlay {
    opacity: 1;
  }
  
  .portraitsDiv:hover .overlay {
    opacity: 1;
  }
  
  .couplesDiv:hover .overlay {
    opacity: 1;
  }
  
  .gradDiv:hover .overlay {
    opacity: 1;
  }
  
  .eventsDiv:hover .overlay {
    opacity: 1;
  }
  
  .seniorDiv:hover .overlay {
    opacity: 1;
  }
  
  .text {
    font-size: 30px;
    padding-top: 45%;
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {

  .categories {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }
  
  .headshotsDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .portraitsDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;
  }
  
  .couplesDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;

  }
  
  .gradDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 2px 2px 2px;
  }
  
  .eventsDiv {
    width: 32.8vw;
    position: relative;
    display: inline-grid;
    margin: 2px 2px 2px;
    background-size: contain;
  }
  
  .seniorDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 2px 2px 2px;
    display: inline-grid;

  }
  
  .portfolio-page-image-1 {
    width: 32.8vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .overlay {
    width: 32.8vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226,222,181,0.5);
  }
  
  .headshotsDiv:hover .overlay {
    opacity: 1;
  }
  
  .portraitsDiv:hover .overlay {
    opacity: 1;
  }
  
  .couplesDiv:hover .overlay {
    opacity: 1;
  }
  
  .gradDiv:hover .overlay {
    opacity: 1;
  }
  
  .eventsDiv:hover .overlay {
    opacity: 1;
  }
  
  .seniorDiv:hover .overlay {
    opacity: 1;
  }
  
  .text {
    font-size: 48px;
    padding-top: 50%;
    text-align: center;
    margin-top: 40px;
  }

}

@media only screen and (min-width: 1201px) {
  .categories {
    text-align: center;
    margin-bottom: 3px;
    margin-top: 103px;

  }
  
  .headshotsDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;
  }
  
  .portraitsDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;
  }
  
  .couplesDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 3px 3px 3px;

  }
  
  .gradDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    display: inline-grid;
    margin: 3px 3px 3px;
  }
  
  .eventsDiv {
    width: 32.8vw;
    position: relative;
    display: inline-grid;
    margin: 3px 3px 3px;

    background-size: contain;
  }
  
  .seniorDiv {
    width: 32.8vw;
    position: relative;
    background-size: contain;
    margin: 3px 3px 3px;
    display: inline-grid;

  }
  
  .portfolio-page-image-1 {
    width: 32.8vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .overlay {
    width: 32.8vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(226,222,181,0.5);
    align-items: center;
  }
  
  .headshotsDiv:hover .overlay {
    opacity: 1;
  }
  
  .portraitsDiv:hover .overlay {
    opacity: 1;
  }
  
  .couplesDiv:hover .overlay {
    opacity: 1;
  }
  
  .gradDiv:hover .overlay {
    opacity: 1;
  }
  
  .eventsDiv:hover .overlay {
    opacity: 1;
  }
  
  .seniorDiv:hover .overlay {
    opacity: 1;
  }
  
  .text {
    font-size: 48px;
    text-align: center;
    margin-top: 40px;
    padding-top: 50%;
  }

}
